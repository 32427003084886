/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Dec 28 2020
 *  File : Commons.jsx
 *******************************************/
import React from 'react';
import PropTypes from 'prop-types';
import Map from 'google-map-react';
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import CustomMapJson from '../../assets/CustomMap.json';
import Images from '../../config/Images';
import styles from './Commons.module.css';
import { Colors } from '../../styles';
import { googleApiKey } from '../../config/constants';
import Head from 'next/head';

/* -----CustomTextInput BEG-----*/
const CustomTextInput = ({
	id,
	type,
	defaultValue,
	placeholder,
	className,
	onChange,
	onFocus,
	onBlur,
	timeStep,
	error,
	errorMessage,
	inputStyle,
	inputRef,
	disabled,
	enableError
}) => {

	const popRef = React.useRef();
	React.useEffect(() => {
		if (error) {
			// $(popRef.current).popover('show');
			// console.log('open popover');
		} else {
			// $(popRef.current).popover('hide');			
			// console.log('close popover');
		}
	}, [error])

	const onChangeHandler = (event) => {
		onChange(event);
	};

	const onLocalFocus = (e) => {
		if (onFocus != null) {
			onFocus(e);
		} else {
			e.currentTarget.type = type;
		}
	};

	const onLocalBlur = (e) => {
		if (onBlur != null) {
			onBlur(e);
		} else if (type !== 'password') {
			e.currentTarget.type = 'text';
		}
	};

	return (
		<div className="form-group" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<input
				id={id}
				defaultValue={defaultValue}
				type="text"
				placeholder={placeholder.toUpperCase()}
				className={className}
				onChange={onChangeHandler}
				onFocus={onLocalFocus}
				onBlur={onLocalBlur}
				step={type === 'time' ? timeStep : null}
				style={inputStyle}
				ref={inputRef}
				disabled={!!disabled}
			/>
			{
				enableError && (
					<span ref={popRef} style={{ color: Colors.GOOGLE_RED, fontSize: 14, fontWeight: 'bold', letterSpacing: 0.8, visibility: error ? "unset" : "hidden" }}>
						{errorMessage}
					</span>
				)
			}
		</div>
	);
};

CustomTextInput.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	timeStep: PropTypes.number,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	inputStyle: PropTypes.instanceOf(Object),
	inputRef: PropTypes.instanceOf(Object),
	disabled: PropTypes.bool,
	enableError: PropTypes.bool,
};

CustomTextInput.defaultProps = {
	id: undefined,
	type: 'text',
	defaultValue: null,
	placeholder: 'PlaceHolder',
	className: null,
	onChange: (e) => console.log(e),
	onFocus: null,
	onBlur: null,
	timeStep: 60,
	error: false,
	errorMessage: 'Please fill the field!',
	inputStyle: {},
	inputRef: {},
	disabled: false,
	enableError: true 
};

/* -----CustomTextInput END-----*/

/* -----CustomButton BEG-----*/
const CustomButton = ({ name, className, onClick }) => {
	const onClickHandler = (event) => {
		onClick(event);
	};

	return (
		<button type="button" className={className} onClick={onClickHandler}>
			{name}
		</button>
	);
};

CustomButton.propTypes = {
	name: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

CustomButton.defaultProps = {
	name: 'Button',
	className: 'cmn-btn',
	onClick: (e) => console.log(e),
};

/* -----CustomButton END-----*/

/* -----CustomMarker START-----*/

const CustomMarker = ({ isSelected, isAnimated, isCluster, clusterCount, onClick , cursor}) => {
	let imageSrc = '';
	if (isSelected) {
		if (isAnimated) {
			imageSrc = Images.markerSelectedAnim
		} else {
			imageSrc = Images.markerSelected
		}
	}
	else if (isAnimated) {
		imageSrc = Images.markerNormalAnim
	}
	else {
		imageSrc = Images.markerNormal
	}

	const onLocalClick = (e) => {
		onClick();
	}

	return (
		isCluster
			?
			<div
				className={styles.clusterContainer}
				onClick={onLocalClick}
				role="button"
				tabIndex={0}
				onKeyDown={() => { }}
				style={{ cursor: cursor ? cursor: 'pointer' }}
			>
				<div className={styles.clusterSpan}>
					{clusterCount}
				</div>
			</div>
			:
			<div
				onClick={onLocalClick}
				role="button"
				tabIndex={0}
				onKeyDown={() => { }}
				style={{ cursor: cursor ? cursor: 'pointer' }}
			>
				<img
					src={imageSrc}
					style={{ width: 35, height: 40 }}
					alt="Custom Marker"
				/>
			</div>
	);
}

CustomMarker.propTypes = {
	isSelected: PropTypes.bool,
	isAnimated: PropTypes.bool,
	isCluster: PropTypes.bool,
	clusterCount: PropTypes.number,
	onClick: PropTypes.func
};

CustomMarker.defaultProps = {
	isSelected: false,
	isAnimated: false,
	isCluster: false,
	clusterCount: 0,
	onClick: () => console.log('clicked')
};

/* -----CustomMarker END-----*/

/* -----CustomMap START-----*/

const CustomMap = ({
	mapRef,
	defaultZoom,
	centerCoordinate,
	markerCoordinates,
	onClickMarker,
	onMapReady,
	onChange,
	children
}) => {

	const createMapOptions = (maps) => ({
		styles: CustomMapJson,
		minZoom: 2,
		maxZoom: 22,
		panControl: false,
		mapTypeId: 'hybrid',
		mapTypeControl: false,
		fullscreenControl: false,
		zoomControl: false
	})

	const getCornerPoints = (map) => {
		onChange(map);
	}

	const onLocalMapReady = (map, maps) => {
		onMapReady(map, maps);
		/**
		 * TODO: mapRef.current.map_ === map
		 */
	}

	return (
		<div style={{ height: '100%', width: '100%' }} className='map-wrap'>
			<Map
				yesIWantToUseGoogleMapApiInternals
				ref={mapRef}
				bootstrapURLKeys={googleApiKey}
				defaultCenter={centerCoordinate}
				defaultZoom={defaultZoom}
				onChange={getCornerPoints}
				onGoogleApiLoaded={({ map, maps }) => onLocalMapReady(map, maps)}
				options={createMapOptions}
			>
				{children && children}
			</Map>
		</div>
	);
};

CustomMap.propTypes = {
	mapRef: PropTypes.instanceOf(Object),
	defaultZoom: PropTypes.number,
	centerCoordinate: PropTypes.instanceOf(Object),
	markerCoordinates: PropTypes.instanceOf(Array),
	onClickMarker: PropTypes.func,
	onMapReady: PropTypes.func,
	onChange: PropTypes.func,
	children: PropTypes.node
};

CustomMap.defaultProps = {
	mapRef: {},
	defaultZoom: 6,
	centerCoordinate: {},
	markerCoordinates: [],
	onClickMarker: (e) => console.log(e),
	onMapReady: (e) => console.log(e),
	onChange: (e) => console.log(e),
	children: null
};

/* -----CustomMap END-----*/

/* -----CustomModal BEG-----*/

/**
 * 
 * @param show
 * @param modalTitle
 * @param modalBody
 * @param showModalFooter
 * @param onClose
 * 
 * You can show/hide modal using `$(modalRef.current).modal('show');`
 */
const CustomModal = ({ show, modalTitle, children, className, onClose }) => {
	const onLocalClose = (e) => {
		onClose(e);
	};
	return (
		<Modal show={show} onHide={onLocalClose} className={className}>
			<Modal.Header closeButton>
				{
					modalTitle && (
						<Modal.Title>{modalTitle}</Modal.Title>
					)
				}
			</Modal.Header>
			<Modal.Body>
				{children}
			</Modal.Body>
		</Modal>
	);
};

CustomModal.propTypes = {
	show: PropTypes.bool,
	modalTitle: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	onClose: PropTypes.func,
};

CustomModal.defaultProps = {
	show: false,
	modalTitle: 'This is Modal Title',
	children: null,
	className: '',
	onClose: () => null,
};

/* -----CustomModal END-----*/

/* -----TreeCounter BEG-----*/

/**
 * 
 * @param message
 * @param enabledBtn
 * @param onClick
 * 
 */

const TreeCounter = ({ message, enabledBtn, onClick }) => {
	const cursor = enabledBtn ? 'pointer' : 'default';
	const backgroundColor = enabledBtn ? '#7dffcd' : '#041a17';
	const color = enabledBtn ? '#242424' : '#ffffff';
	return (
		message && (
			<div className={styles.counterContainer}>
				<div
					role="button"
					tabIndex={0}
					className={styles.counter}
					onKeyDown={() => { }}
					onClick={enabledBtn ? onClick : () => { }}
					style={{ cursor, backgroundColor, color }}
				>
					<img src={Images.markerSelectedAnim} alt="marker selected" style={{ width: 30, height: 30 }} />
					<span className={styles.counterMessage}>{message}</span>
				</div>
			</div>
		)
	);
}

TreeCounter.propTypes = {
	message: PropTypes.string,
	enabledBtn: PropTypes.bool,
	onClick: PropTypes.func
};

TreeCounter.defaultProps = {
	message: '',
	enabledBtn: false,
	onClick: (e) => console.log(e)
};

/* -----TreeCounter END-----*/

/* -----CustomNotification BEG-----*/

/**
 * 
 * @param show
 * @param notificationTitle
 * @param notificationMessage
 * @param onAcceptNotification
 * @param onClose
 * 
 * You can show/hide modal using `$(notificationRef.current).modal('show');`
 */
const CustomNotification = ({
	show,
	notificationTitle,
	notificationMessage,
	enableButtonOne,
	enableButtonTwo,
	buttonActionOne,
	buttonActionTwo,
	buttonTextOne,
	buttonTextTwo,
	onClose
}) => {

	const localButtonActionOne = (e) => {
		buttonActionOne(e);
	};

	const localButtonActionTwo = (e) => {
		buttonActionTwo(e);
	};

	const onLocalClose = () => {
		onClose();
	}

	return (
		<Modal show={show} onHide={onLocalClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{notificationTitle}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{notificationMessage}
			</Modal.Body>
			<Modal.Footer>
				{
					enableButtonOne && (
						<button
							type="button"
							className={styles.acceptButtonNotice}
							onClick={localButtonActionOne}
							data-dismiss="modal"
						>
							{buttonTextOne}
						</button>
					)
				}
				{
					enableButtonTwo && (
						<button
							type="button"
							className={enableButtonOne ? styles.rejectButtonNotice : styles.acceptButtonNotice}
							onClick={localButtonActionTwo}
							data-dismiss="modal"
						>
							{buttonTextTwo}
						</button>
					)
				}
			</Modal.Footer>
		</Modal>
	);
};

CustomNotification.propTypes = {
	show: PropTypes.bool,
	notificationTitle: PropTypes.string,
	notificationMessage: PropTypes.string,
	enableButtonOne: PropTypes.bool,
	enableButtonTwo: PropTypes.bool,
	buttonActionOne: PropTypes.func,
	buttonActionTwo: PropTypes.func,
	buttonTextOne: PropTypes.string,
	buttonTextTwo: PropTypes.string,
	onClose: PropTypes.func
};

CustomNotification.defaultProps = {
	show: false,
	notificationTitle: 'Warning',
	notificationMessage: null,
	enableButtonOne: true,
	enableButtonTwo: true,
	buttonActionOne: () => { },
	buttonActionTwo: () => { },
	buttonTextOne: 'Yes',
	buttonTextTwo: 'No',
	onClose: () => { }
};

/* -----CustomNotification END-----*/

/* -----CustomLoader BEG-----*/

/**
 * 
 * @param showLoader
 * 
 */

const CustomLoader = ({
	showLoader
}) => (
	showLoader && (
		<div className={styles.loaderContainer}>
			<img
				src={Images.loader}
				className={styles.loaderImage}
				alt="loader"
			/>
		</div>
	)
);

CustomLoader.propTypes = {
	showLoader: PropTypes.bool,
};

CustomLoader.defaultProps = {
	showLoader: false,
};

/* -----CustomLoader END-----*/

/* -----CustomHeader BEG-----*/

const CustomHeader = () => {

	return (
    <Head>
      <title>GiftTrees</title>
      <script defer src="/vendor/jQuery/jquery.min.js"></script>
      <script defer src="/vendor/bootstrap/js/bootstrap.bundle.js"></script>
      <script defer src="/vendor/gtm/headTag.js"></script>
      <meta property="og:image" content="/images/SR_OG_GiftTrees.jpg"></meta>
      <meta property="og:image:type" content="image/jpg"></meta>
      <meta property="og:image:width" content="1024"></meta>
      <meta property="og:image:height" content="1024"></meta>
    </Head>
  );
};

/* -----CustomHeader END-----*/

/* -----CustomTimePicker BEG-----*/
const CustomTimePicker = ({
	placeholder,
	onChange,
	inputStyle
}) => {

	const currentTime = new Date();
	const domRef = React.useRef(null);
	const [state, setState] = React.useState({
		time: null,
		hour: currentTime.getHours(),
		minute: currentTime.getMinutes(),
		showPicker: false,
		hasStartedInput: false
	});

	React.useEffect(() => {
		document.addEventListener('click', onClickOutside, true);
		return () => {
			document.removeEventListener('click', onClickOutside, true);
		}
	}, [state.time, state.showPicker]);

	// it will listen to event when user clicks outside DOM
	const onClickOutside = (e) => {
		const dom = domRef.current;
		if (dom && !dom.contains(e.target) && state.showPicker) {
			onBlurTimer();
		}
	}

	// format x => 0x 
	const toTimeFormat = (i) => i.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

	// validate if i => HH:MM
	const timeValidator = (i) => /^\d{2}:\d{2}$/.test(i);

	const onLocalChangeText = (e) => {
		const newState = { ...state };
		const inputValue = e.target.value;
		/**
		 * Time format should be HH:MM
		 * Check to see if the value is legal
		 */
		if (timeValidator(inputValue)) {
			// break the input to hour and minute
			const [hour, minute] = inputValue.split(':');
			if (hour <= 23 && minute <= 59) {
				newState.hour = hour;
				newState.minute = minute;
			}
		}
		newState.time = inputValue;
		setState(newState)
		// onChange(e);
	}

	const onFocusTimer = () => {
		if (!state.showPicker) {
			console.log('focused');
			setState({
				...state,
				showPicker: true,
				hasStartedInput: true
			})
		}
	}

	const onBlurTimer = () => {
		// in this stage we have finalized the
		// user entered input
		const newState = { ...state };
		if (state.showPicker) {
			console.log(state.time);
			// check if user has entered valid time
			if (!timeValidator(state.time)) {
				// illegal time value entered
				newState.time = '';
			}
			newState.showPicker = false;
		}
		// pass the value to parent
		onChange({
			target: {
				value: newState.time
			}
		})
		// set current state
		setState(newState);
	}

	const onUpdateButtonHandler = (type, target) => {
		const newState = { ...state };
		if (type === 'increment') {
			// increase
			if (target === 'hour') {
				// check if current stored hour is > -1 and < 24
				newState.hour = Number(state.hour) >= 23 ? 0 : Number(state.hour) + 1;
			} else if (target === 'minute') {
				// if target is minutes
				// check if current stored minute is > -1 and < 60
				newState.minute = Number(state.minute) >= 59 ? 0 : Number(state.minute) + 1;
			}
		} else if (type === 'decrement') {
			// decrease
			if (target === 'hour') {
				// check if current stored hour is > -1 and < 24
				newState.hour = Number(state.hour) <= 0 ? 23 : Number(state.hour) - 1;
			} else if (target === 'minute') {
				// if target is minutes
				// check if current stored minute is > -1 and < 60
				newState.minute = Number(state.minute) <= 0 ? 59 : Number(state.minute) - 1;
			}
		}

		newState.time = `${toTimeFormat(newState.hour)}:${toTimeFormat(newState.minute)}`;

		setState(newState);
	}


	return (
		<div ref={domRef}>
			<input
				type="text"
				placeholder={placeholder}
				onFocus={onFocusTimer}
				onChange={onLocalChangeText}
				style={inputStyle}
				value={state.time}
			/>
			<button className="btn-datepicker" onClick={onFocusTimer} type="button">
				<img alt="Bill Time" src="../images/clock.png" />
			</button>
			{/**
			 * Below this line is the render for timepicker div
			 */}
			{
				state.showPicker && (
					<div className={styles.timePickerContainer}>
						<div className={styles.pickerColumn}>
							<button type="button" onClick={() => onUpdateButtonHandler('increment', 'hour')}>
								<i className="fa fa-chevron-up" />
							</button>
							<span>{toTimeFormat(state.hour)}</span>
							<button type="button" onClick={() => onUpdateButtonHandler('decrement', 'hour')}>
								<i className="fa fa-chevron-down" />
							</button>
						</div>

						<div className={styles.pickerColumn}>
							<span>&nbsp;</span>
							<span>:</span>
							<span>&nbsp;</span>
						</div>

						<div className={styles.pickerColumn}>
							<button type="button" onClick={() => onUpdateButtonHandler('increment', 'minute')}>
								<i className="fa fa-chevron-up" />
							</button>
							<span>{toTimeFormat(state.minute)}</span>
							<button type="button" onClick={() => onUpdateButtonHandler('decrement', 'minute')}>
								<i className="fa fa-chevron-down" />
							</button>
						</div>
					</div>
				)
			}
		</div>
	);
}

CustomTimePicker.propTypes = {
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	inputStyle: PropTypes.instanceOf(Object)
}

CustomTimePicker.defaultProps = {
	placeholder: 'Time Picker',
	onChange: (e) => console.log(e),
	inputStyle: {}
}
/* -----CustomTimePicker END-----*/

/* -----CustomImageModal START-----*/

const CustomImageModal = ({
	show,
	imgSrc,
	onHide
}) => (
	show
	&&
	<div className={styles.imageModal} style={{ display: 'block' }}>
		<span
			role="button"
			tabIndex={0}
			className={styles.imageModalClose}
			onClick={onHide}
			onKeyDown={() => { }}
		>
			&times;
			</span>
		<img className={styles.imageModalContent} id="img01" alt="modal content" src={imgSrc} />
	</div>
);

CustomImageModal.propTypes = {
	show: PropTypes.bool,
	imgSrc: PropTypes.string,
	onHide: PropTypes.func
};

CustomImageModal.defaultProps = {
	show: false,
	imgSrc: null,
	onHide: () => { }
};

/* -----CustomImageModal END-----*/

/* -----CustomTextInputWithTooltip BEG-----*/
const CustomTextInputWithTooltip = ({
	type,
	defaultValue,
	placeholder,
	className,
	onChange,
	onFocus,
	onBlur,
	timeStep,
	error,
	errorMessage,
	inputStyle,
	inputRef
}) => {


	const [showToolTip, setShowToolTip] = React.useState(false);

	const onChangeHandler = (event) => {
		// we can hide tooptip once user has started typing
		// and show him once he has cleared the input
		if(showToolTip) {
			if(event.target.value.length > 0) {
				setShowToolTip(false);
			}
		} else if(event.target.value.length === 0) {
			setShowToolTip(true)
		};

		onChange(event);
	};

	const onLocalFocus = (e) => {
		if (onFocus != null) {
			onFocus(e);
		} else {
			e.currentTarget.type = type;
		}

		if(!showToolTip && e.currentTarget.value.length === 0) setShowToolTip(true);
	};

	const onLocalBlur = (e) => {
		if (onBlur != null) {
			onBlur(e);
		} else if (type !== 'password') {
			e.currentTarget.type = 'text';
		}

		// hide tooltip on blur
		if(showToolTip) setShowToolTip(false);
	};

	return (
		<div className="form-group" style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
			<OverlayTrigger 
				placement="top"
				trigger="focus"
				show={ showToolTip }
				overlay={
					<Tooltip id="overlay-example">
						Start typing the name of the restaurant you dined at.
					</Tooltip>
				}				
			>
				<input
					defaultValue={ defaultValue }
					type="text"
					placeholder={ placeholder.toUpperCase() }
					className={ className }
					onChange={ onChangeHandler }
					onFocus={ onLocalFocus }
					onBlur={ onLocalBlur }
					step={ type === 'time' ? timeStep : null }
					style={ inputStyle }
					ref={ inputRef }
				/>
			</OverlayTrigger>
			{
				error && (
					<span 
						style={ { color: Colors.GOOGLE_RED, fontSize: 14, fontWeight: 'bold', letterSpacing: 0.8 } }
					>
						{errorMessage}
					</span>
				)
			}
		</div>
	);
};

CustomTextInputWithTooltip.propTypes = {
	type: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	timeStep: PropTypes.number,
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	inputStyle: PropTypes.instanceOf(Object),
	inputRef: PropTypes.instanceOf(Object)
};

CustomTextInputWithTooltip.defaultProps = {
	type: 'text',
	defaultValue: null,
	placeholder: 'PlaceHolder',
	className: null,
	onChange: (e) => console.log(e),
	onFocus: null,
	onBlur: null,
	timeStep: 60,
	error: false,
	errorMessage: 'Please fill the field!',
	inputStyle: {},
	inputRef: {}
};

/* -----CustomTextInputWithTooltip END-----*/
/**
 * 
 * @param image_url
 * @returns 
 */
const TreePopUp = ({ image_url, species, farmer_first_name,farmer_last_name ,farmer_image_url, lat, lng}) => {

	let locationClass="";

	if (!species) {
		locationClass = "text-center col-12"
	} else {
		locationClass = "text-left col-6"
	}


	return (
		<div>
			<div className='treeImageContainer'>
				<img src={image_url} alt="tree" className={`img-fluid treeImage`} />
			</div>
			<div className='treeInfo'>
				<div className='row'>
				<div className='col-6 '>
					{species && <div className='tree-species-container'>
						<label>Species</label>
						<p>{species}</p>
					</div>}
					</div>
					<div className={locationClass}>
						<label>Location</label>
						<p>{Math.round(lat * 100000) / 100000}, {Math.round(lng * 100000) / 100000} </p>
					</div>
				</div>
			</div>
			{ (farmer_image_url || farmer_first_name ) && <div className='farmerInfo'>
				<h4>Meet the Farmer</h4>
				{farmer_image_url && <div className='farmerImage' style={{ 'background-image': "url('" + farmer_image_url + "')" }}></div>}
				{(farmer_first_name || farmer_last_name) && <p>Hi, I'm <span className="text-capitalize">{farmer_first_name} {farmer_last_name}</span></p>}
				{(farmer_first_name || farmer_last_name) &&<p>I planted this tree!</p>}
			</div>}
		</div>
	);
};
TreePopUp.propTypes = {
	image_url: PropTypes.string,

};

TreePopUp.defaultProps = {
	image_url: '',
	
};

export {
	CustomTextInput,
	CustomButton,
	CustomMap,
	CustomMarker,
	CustomModal,
	TreeCounter,
	CustomNotification,
	CustomLoader,
	CustomHeader,
	CustomTimePicker,
	CustomImageModal,
	CustomTextInputWithTooltip,
	TreePopUp
};
