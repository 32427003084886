/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Dec 21 2020
 *  File : Colors.js
 *******************************************/
export const TRANSPARENT = 'rgba(52, 52, 52, 0)';
export const SEMI_TRANSPARENT_DARK = 'rgba(0, 0, 0, 0.3)';

export const WHITE = '#FFFFFF';
export const SEMI_TRANSPARENT_WHITE = 'rgba(255, 255, 255, 0.5)';
export const GREY = '#c7c7c7';
export const BOULDER = '#7c7c7c';
export const BLACK = '#000000';
export const TUNDORA = '#444444';
export const MID_GREY = '#636364';

export const NICE_BLUE = '#176BBD';
export const FB_BLUE = '#1877f2';
export const CURIOUS_BLUE = '#1f88e7';
export const ALICE_BLUE = '#eff7ff';
export const TORY_BLUE = '#0b59a4';
export const SEMI_TRANSPARENT_TORY_BLUE = 'rgba(11, 89, 164, 0.75)';
export const MIDNIGHT = '#00192f';

export const SHAMROCK = '#38cb7d';

export const PUNCH = '#DE4B39';
export const GOOGLE_RED = '#de4b39';
export const SUNSET_ORANGE = '#ff4d4d';
export const APPROVED_GREEN = '#008000';
export const DARK_MINT = '#38cb7d';
export const ORANGE = '#FFA500';
export const TEAL = '#008080';
export const EAST_BAY = '#3c547b';
export const FLAME_PEA = '#dc582c';
export const CRISTI = '#829311';
export const DAISY_BUSH = '#542991';
export const GRAVEL = '#464349';
export const CHARM = '#da6d9c';
export const METEOR = '#d78613';
export const HIPPIE_BLUE = '#4aa5ac';
export const SLATE_GRAY = '#6e8591';
export const TAPESTRY = '#bb5e8c';
export const KILLARNEY = '#336a3a';
export const TRENDY_GREEN = '#859514';
export const APPLE = '#3f953e';
export const EAST_BAY_TWO = '#3b5078';
export const ASPARAGUS = '#72964b';
export const STUDIO = '#9052ae';
export const ROYAL_PURPLE = '#5f37a2';
export const OCEAN_GREEN = '#4ba592';
export const SHADOW = '#917e4b';
export const KEPPEL = '#35a3aa';
export const PIPER = '#d24c23';
export const MARTINIQUE = '#2d2949';
export const BUTTERED_RUM = '#a37710';
export const CROWN_OF_THRONS = '#7f2737';
export const LIMEADE = '#3E8F00';
export const IRON = '#e0e1e2';
export const SAN_MARINO = '#4267B2';
export const DUSTY_GRAY = '#979797';
export const AQUA_HAZE = '#EFF3F6';
export const DUSTY_GRAY_LIGHTER = '#949494';
export const SEMI_TRANSPARENT_DARKER = 'rgba(0, 0, 0, 0.6)';

export const AQUA_HAZE_LIGHT = 'rgba(239, 243, 246, 0.7)';

export const MIDNIGHT_LIGHT = 'rgba(0, 25, 47, 0.5)';
